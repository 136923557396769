<template>
  <content-holder
    v-if="canViewPayerDetails"
    :title="'Taxpayers Details'"
    class="p-6"
  >
    <div class="lg:container sm:mx-auto px-4 sm:px-8 bg-gray-100">
      <div class="py-8">
        <div>
          <!-- <h2 class="text-2xl font-semibold leading-tight">Users</h2> -->
        </div>
        <div class="my-2 flex sm:flex-row flex-col sm:items-center">
          <div
            class="flex sm:flex-row flex-col space-y-2 sm:space-y-0 mb-1 sm:mb-0"
          >
            <div class="relative">
              <select
                v-model="payload.limit"
                @change="fetchTaxPayers"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
            <div class="relative">
              <select
                v-model="payload.registration_type"
                @change="fetchTaxPayers"
                class="h-full rounded border sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
              >
                <option value="">Select Type</option>
                <option value="all">All</option>
                <option value="individual">Individual</option>
                <option value="corporate">Corporate</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
            <div class="relative">
              <select
                v-model="payload.sector"
                @change="fetchTaxPayers"
                class="h-full rounded border sm:rounded-r-none sm:border-r-0 sm:border-l border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
              >
                <option value="">Select Sector</option>
                <option value="all">All</option>
                <option v-for="sector in sectors" :key="sector" :value="sector">
                  {{ sector }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <svg
                viewBox="0 0 24 24"
                class="h-4 w-4 fill-current text-gray-500"
              >
                <path
                  d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
                ></path>
              </svg>
            </span>
            <input
              placeholder="Search"
              v-model="payload.name"
              @input="fetchTaxPayersWithName"
              class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            />
          </div>
          <div class="w-full text-center h-9 sm:w-12">
            <i
              v-if="fetchingData"
              :class="`lg:focus:shadow-sm w-12 text-center fas fa-circle-notch mt-4 text-md text-yellow-500 animate-spin`"
            ></i>
          </div>
        </div>
        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div
            class="inline-block min-w-full shadow rounded-lg overflow-hidden"
          >
            <table class="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    TIN
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Phone
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Address
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Sector
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    REG Date / DOB
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    CAC NUM
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Tax Office
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    TIN Authority
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="taxpayer in taxpayers.result"
                  :key="taxpayer.tin"
                  @click="showPayments(taxpayer)"
                  class="cursor-pointer hover:bg-gray-200 hover:font-semibold"
                >
                  <td
                    class="px-5 py-0 border-dashed border-b border-gray-200 bg-white text-sm"
                  >
                    <p
                      class="text-gray-900 whitespace-no-wrap flex flex-col h-20 justify-center w-56"
                    >
                      {{ taxpayer.name }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-0 border-dashed border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ taxpayer.tin }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-0 border-dashed border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ taxpayer.email }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-0 border-dashed border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ taxpayer.phone_number }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-0 border-dashed border-b border-gray-200 bg-white text-sm"
                  >
                    <p
                      class="text-gray-900 whitespace-no-wrap w-96 flex flex-col h-20 justify-center"
                    >
                      {{ taxpayer.address ? taxpayer.address : "N/A" }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-0 border-dashed border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{
                        taxpayer.business_sector
                          ? taxpayer.business_sector
                          : "N/A"
                      }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-0 border-dashed border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap w-20">
                      {{
                        taxpayer.dt === "none" ? "N/A" : formatDate(taxpayer.dt)
                      }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-0 border-dashed border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{
                        taxpayer.cac_number == "null"
                          ? "N/A"
                          : taxpayer.cac_number
                      }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-0 border-dashed border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ taxpayer.tax_office ? taxpayer.tax_office : "N/A" }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-0 border-dashed border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ taxpayer.tin_authority }}
                    </p>
                  </td>
                </tr>
                <p
                  v-if="!taxpayers.result || taxpayers.result.length < 1"
                  class="px-4 py-6 text-xl w-full text-center sm:text-left"
                  style="width: max-content"
                >
                  No result
                </p>
              </tbody>
            </table>
            <div
              class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between"
            >
              <!-- <span class="text-xs xs:text-sm text-gray-900">
                Showing {{firstPosition}} to {{middlePosition}} of {{totalCount}} Entries
              </span> -->
              <div class="inline-flex mt-2 xs:mt-0">
                <button
                  v-show="taxpayers.previous"
                  @click="fetchPreviousRecords"
                  class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                >
                  Prev
                </button>
                <button
                  @click="fetchNextRecords"
                  v-show="taxpayers.next"
                  class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                >
                  Next
                </button>
              </div>
              <span
                v-if="taxpayers.result"
                class="text-xs xs:text-sm text-gray-900"
              >
                Showing {{ taxpayers.result.length }} of
                {{ totalCount }} Entries
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </content-holder>
  <content-holder v-else :title="'Taxpayers Details'" class="p-4">
    <unauthorized-access></unauthorized-access>
  </content-holder>
</template>

<script>
import ContentHolder from "../components/ContentHolder.vue";
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import { views } from "@/helpers/login_details.js";
export default {
  components: {
    ContentHolder,
    UnauthorizedAccess,
  },
  data: () => ({
    canViewPayerDetails: false,
    sectors: [
      "Agriculture",
      "Aviation",
      "Commercial/ Retail",
      "Construction",
      "Education and Training",
      "Energy and Power Generation",
      "FMCG",
      "Fashion",
      "Financial Services",
      "Haulage/Logistics",
      "Healthcare",
      "ICT",
      "Manufacturing",
      "Media and Entertainment",
      "Oil and Gas",
      "Professional Services",
      "Telecommunication",
      "Tourism/Hospitality",
      "Transportation",
      "Waste Management",
    ],
    payload: {
      limit: 5,
      page: 1,
      registration_type: "",
      sector: "",
      name: "",
    },
    fetchingData: true,
    firstPosition: 1,
    middlePosition: 1,
    totalCount: 0,
  }),
  watch: {},
  computed: {
    ...mapGetters({
      taxpayers: ["taxpayerInfo/getTaxpayers"],
    }),
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD hh:mm:ss");
    },
    showPayments(taxpayer) {
      this.$router
        .replace({ name: "tpdb", params: { name: taxpayer.name } })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchTaxPayers() {
      try {
        this.fetchingData = true;
        this.middlePosition = this.payload.limit;
        if (this.payload.name === "") delete this.payload.name;
        await this.$store.dispatch("taxpayerInfo/fetchTaxPayers", this.payload);

        // if(this.taxpayers.next)
        this.totalCount = this.taxpayers.total;
        this.fetchingData = false;
        // else this.totalCount = this.taxpayers.result.length;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTaxPayersWithName() {
      try {
        this.fetchingData = true;
        this.middlePosition = this.payload.limit;
        this.payload.page = 1;
        await this.$store.dispatch("taxpayerInfo/fetchTaxPayers", this.payload);
        this.totalCount = this.taxpayers.total;
        this.fetchingData = false;
      } catch (error) {
        console.log(error);
        this.fetchingData = false;
      }
    },
    fetchNextRecords() {
      try {
        if (this.taxpayers.next) {
          this.payload.page = this.taxpayers.next.page;
          this.fetchTaxPayers();
        }
      } catch (error) {
        console.log(error);
      }
    },
    fetchPreviousRecords() {
      try {
        if (this.taxpayers.previous) {
          this.payload.page = this.taxpayers.previous.page;
          this.fetchTaxPayers();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    try {
      this.middlePosition = this.payload.limit;
      this.fetchTaxPayers();
    } catch (error) {
      console.log(error);
    }

    if (views.includes("PayerDetails")) {
      this.canViewPayerDetails = true;
    } else {
      this.canViewPayerDetails = false;
    }
  },
};
</script>

<style></style>
